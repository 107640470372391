<template>
    <div class="template-page yellow-step seminar-page">
        <basic-page :pagedata="this.seminarData" :bannerimg="this.seminarImage">
            <div class="person-header step-big angle yellow-angle" v-if="this.seminarData.attributes">
                <div class="date">
                    <div class="from-box box">
                        <div style="color: #fff;" v-if="!this.sameDay">
                        FROM
                        </div>
                        <p class="day">{{ seminarStartDate.getDate() }}</p>
                        <p class="month">{{ month }}</p>
                        <p class="year">{{ seminarStartDate.getFullYear() }}</p>
                    </div>
                    <div class="to-box box">
                        <div style="color: #fff;" v-if="!this.sameDay">
                        TO
                        <p class="day">{{ seminarEndDate.getDate() }}</p>
                        <p class="month">{{ month }}</p>
                        <p class="year">{{ seminarEndDate.getFullYear() }}</p>
                        </div>
                    </div>
                </div>
                <div class="hour speaker">
                    <span class="speaker-tag" v-if="allSpeakers.length > 0">location</span>
                    <p class="room">{{ this.seminarData.attributes.field_seminar_location }}</p>
                    <p class="hours">{{ this.sameDay ? (this.startHour + ' - ' + this.endHour) : this.startHour }}</p>
                </div>
                <div class="speaker" >
                    <span class="speaker-tag" v-if="allSpeakers.length > 0">speaker</span>
                    <div v-for="(speaker, index) in allSpeakers" :key="index" >
                    <router-link v-if="speaker.type=='internal'" :to="personLink(speaker)" class="speaker-link">
                        <p class="speaker-name">{{speaker.name}}</p>
                    </router-link>
                    <p v-else class="speaker-name">{{speaker.name}}</p>
                    </div>
                </div>
            </div>
            <!-- <div class="image-content">
                <img :src="seminarImageContent" alt="">
            </div> -->
            <div class="content" v-if="this.seminarData.attributes && this.seminarData.attributes.field_seminar_description"
                v-html="this.seminarData.attributes.field_seminar_description.value">
            </div>
            <div class="tags" v-if="this.seminarData.field_ref_seminar_tag.length>0">
                <h4>TAGS</h4>
                <p><span v-for="(tag, index) in this.seminarData.field_ref_seminar_tag" :key="'tag'+index">
                    {{ tag.attributes.name + (index==seminarData.field_ref_seminar_tag.length-1 ? '' : ', ') }}
                </span></p>
            </div>

            <br>

            <h3 v-if="this.seminarData.field_seminar_attachment && this.seminarData.field_seminar_attachment.length">Attachment/s</h3>
            <div v-if="seminarData.field_seminar_attachment && seminarData.field_seminar_attachment.length">
                <div v-for="(attachment, index) in seminarData.field_seminar_attachment" :key="index">
                    <a v-if="seminarData.relationships.field_seminar_attachment.data[index].meta.description"
                        class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                        {{ seminarData.relationships.field_seminar_attachment.data[index].meta.description }}</a>
                    <a v-else class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                        {{ attachment.attributes.filename }}
                    </a>
                </div>
            </div>

            <br>
            <div class="back-button">
                <router-link to="/seminars-and-journal-clubs" class="cta-link">
                    <span>Back to Seminars</span>
                </router-link>
            </div>
        </basic-page>
    </div>
</template>

<script>
import BasicPage from '../BasicPage.vue'
import { fetchNodes, fetchSingleNode } from '../../libs/drupalClient';
import { convertMonth, detailsLink  } from '../../libs/utils'
    export default {
        name: 'seminar',
        components: {
            BasicPage
        },
        data: () => {
            return {
                seminarData: {
                    title: '',
                    body: {
                        summary: ''
                    }
                },
                introimg: '',
                introimgbannerID: '24f1b3a9-d43d-46dc-b61f-a524eb6e6c1f'
            }
        },
        computed: {
            seminarNID() {
                return this.$route.params.nid
            },
            startHour() {
                let d = new Date(Date.parse(this.seminarData.attributes.field_seminar_start_date))
                let h = d.getHours()
                let m = d.getMinutes()==0 ? '00' : d.getMinutes().toString()
                if(h>12) h = (h - 12).toString() + ':' + m + ' pm'
                else h = h.toString() + ':' + m + ' am'
                return h
                },
            endHour() {
                let d = new Date(Date.parse(this.seminarData.attributes.field_seminar_end_date))
                let h = d.getHours()
                let m = d.getMinutes()==0 ? '00' : d.getMinutes().toString()
                if(h>12) h = (h - 12).toString() + ':' + m + ' pm'
                else h = h.toString() + ':' + m + ' am'
                return h
            },
            sameDay() {
                return (this.seminarStartDate.getDate() == this.seminarEndDate.getDate()
                    && this.seminarStartDate.getMonth() == this.seminarEndDate.getMonth()
                    && this.seminarStartDate.getFullYear() == this.seminarEndDate.getFullYear())
            },
            allSpeakers() {
                var tot = []

                this.seminarData.attributes.field_seminar_external_speaker.forEach(esp => {
                    tot.push({ type: 'external', name: esp })
                });
                this.seminarData.field_seminar_internal_speaker.forEach(isp => {
                    tot.push({ type: 'internal', name: isp.attributes.field_first_name + ' ' + isp.attributes.field_last_name, nid: isp.attributes.drupal_internal__nid })
                })
                //console.log(tot)

                return tot.sort((a, b) =>  {
                    return a.name.split(' ')[1] > b.name.split(' ')[1] ? 1 : -1
                })
            },
            seminarStartDate() {
                return new Date(Date.parse(this.seminarData.attributes.field_seminar_start_date))
            },
            seminarEndDate() {
                return new Date(Date.parse(this.seminarData.attributes.field_seminar_end_date))
            },
            month() {
                return convertMonth(this.seminarStartDate.getMonth())
            },
            seminarImage() {
                return process.env.VUE_APP_ENDPOINT + this.introimg 
                //+ (this.seminarData.field_seminar_image ? this.seminarData.field_seminar_image.attributes.uri.url : this.introimg)
            },
            seminarImageContent() {
                return this.seminarData.field_seminar_image ? process.env.VUE_APP_ENDPOINT + this.seminarData.field_seminar_image.attributes.uri.url : ''
            }
        },
        methods: {
            fileSrc(att) {
                return process.env.VUE_APP_ENDPOINT + att.attributes.uri.url
            },
            personLink(p) {
                //console.log(p)
                var sp = {
                    attributes: {
                    field_first_name: p.name.split(' ')[0],
                    field_last_name: p.name.split(' ')[1],
                    drupal_internal__nid: p.nid
                    }
                }
                return detailsLink('person', sp)
            },
            fetchSeminar() {
                if (this.seminarNID) {
                    fetchNodes('seminar', {
                        filters: [{
                            key: 'drupal_internal__nid',
                            value: this.seminarNID
                        }],
                        include: ['field_seminar_image', 'field_seminar_internal_speaker', 'field_ref_seminar_tag' , 'field_ref_seminar_tag', 'field_seminar_attachment']
                    }).then(res => {
                        this.seminarData = res[0]
                        this.seminarData.title = this.seminarData.attributes.title
                        //console.log(this.seminarData);
                    })
                }
            },
            fetchDefaultBanner() {
                fetchSingleNode('page', this.introimgbannerID, {
                    include: ['field_banner_top']
                }).then(res => {
                    this.introimg = res[0].field_banner_top.attributes.uri.url
                })
            }
        },
        mounted() {
            this.fetchSeminar()
            this.fetchDefaultBanner()
        }
    }
</script>

<style lang="scss" scoped>
@import "../../styles/colors";

.tags {
    h4 {
        margin-top: 2rem;
    }
    p {
        color: #636d7e;
        font-style: italic;
    }
}

.seminar-page {
    .image-content {
        margin-bottom: 20px;
        width: 50%;
        img {
            width: 100%;
        }
    }
    .to-box {
        margin-left: 50px;
    }
    .person-header {
        border-top: 5px solid $mainColor;
        background-color: $secondaryColor;
    }

    .step-big::before {
        background-color: $secondaryColor;
    }

    .person-header {
        .date {
            display: flex;
            flex-direction: row;
        }
        > div {
            position: relative;
            z-index: 9;
            margin: -40px 0px;
            border: 0px;
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            padding: 40px 6rem 40px 0rem;
            display: flex; display: -webkit-flex;
            flex-direction: column;
            justify-content: center;

            > * {
                padding-left: 3rem;
            }
        }

        >div:first-child {
            > * {
                padding-left: 0rem;
            }
        }

        .date:after {
            display: none;
        }

        .month {
            color: #fff;
        }

        .hours {
            margin: 10px 0px 10px 0px;
            font-size: 1.875rem;
            font-weight: 400;
            color: #fff;

            span {
                font-size: 1.25rem;
            }
        }

        .room {
            font-size: 1.25rem;
            font-weight: 400;
        }
    }
}

.yellow-step .step-big:after {
    z-index: 9;
    background: transparent;
}

.speaker-tag {
    margin: 10px 0px 10px 0px;
}

.speaker-name {
    color: #fff;
    margin: 10px 0px 10px 0px;
    font-weight: 400;
    font-size: 1.875rem;
}

@media (max-width: 1200px) {
    .seminar-page .person-header > div {
        padding: 40px 4rem 40px 0rem;

        .day {
            font-size: 2.5rem;
            font-weight: 400;
        }

        .month {
            font-size: 1.375rem;
        }

        .speaker-name,
        .room,
        .hours {
            font-size: 1.375rem;
        }
    }
}

@media (max-width: 1200px) {
    .seminar-page .person-header {
        display: flex;
        display: -webkit-flex;

        .speaker-tag,
        .speaker-name {
            margin: 2px 0px 2px 0px;
        }
    }
}

@media (max-width:1024px) {
    .seminar-page .person-header > div > *{
        padding-left: 2rem;
    }

    .seminar-page .person-header > div {
        padding: 40px 2rem 40px 0rem;
    }   

    .speaker-link {
        display: block;
        position: relative;

        .speaker-name{
            display: inline-block;

            &:after {
                top: 2px;
                right: auto;
                position: absolute;
                margin-left: 12px;
                content: url("../../assets/arrow-link.svg");
            }
        }
    }
}


@media (max-width: 768px) {
    .seminar-page .person-header .date{
        justify-content: start;
    }
    .seminar-page .image-content {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .seminar-page .person-header {
        display: flex;
        display: -webkit-flex;

        >div {
            padding: 40px 2rem 40px 0rem;
            border-right: 1px solid rgba(255, 255, 255, 0.1);

            >* {
                padding-left: 2rem;
            }
        }
    }
}

@media (max-width: 576px) {
    .seminar-page .person-header {
        display: flex; display: -webkit-flex;

        >div {
            margin: 0;
            padding: 9px 0rem;
            border-right: 0px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            >* {
                padding-left: 0rem;
            }
        }

        div {

            .day {
                font-size: 1.875rem;
                font-weight: 400;
            }

            .month {
                font-size: 1.125rem;
            }

            .speaker-name,
            .room,
            .hours {
                font-size: 1.125rem;
            }
        }
    }
}
</style>